let msg = undefined;
let timer = -1;

export default (text, status, duration) => {
    let icon = '';
    if (typeof status != 'undefined') {
        if (status === true || status == 'true' || status == 'success') {
            icon = '<i class="fa fa-check" aria-hidden="true"></i>';
        } else if (status === false || status == 'false' || status == 0 || status == 'fail') {
            icon = '<i class="fa fa-times" aria-hidden="true"></i>';
        } else {
            icon = '<i class="fa fa-info-circle" aria-hidden="true"></i>';
        }
    }

    if (msg) {
        msg.remove();
        msg = undefined;
        if (timer > -1) {
            clearTimeout(timer);
            timer = -1;
        }
    }

    let html = `<div class="alert-msg">
                    <span class="msg-icon">${icon}</span>
                    <span class="msg-text">${text}</span>
                </div>`;

    msg = $(html);

    $('body').append(msg);

    msg.css({
        'marginLeft': - msg.outerWidth() / 2
    });
    msg.fadeIn('fast');
    if (duration != -1) {
        if (!duration) {
            duration = 2;
        }

        timer = setTimeout(function () {
            msg.remove();
            msg = undefined;
        }, duration * 1000);
    }
}