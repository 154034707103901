import './components/dialog';
import prompt from './components/prompt';
import './old/global';

window.AconfGlobal = window.AconfGlobal || {};
window.AconfGlobal.prompt = prompt;

$(function() {

    /* 鼠标经过下拉 */
    $(".drop_menu_hover").mouseenter(function () {
        $(this).children(".sub_menu_hover").stop(true, true).slideDown("fast");
    }).mouseleave(function () {
        $(this).children(".sub_menu_hover").stop(true, true).slideUp("fast");
    });

    /* 提示信息 */
    $('[data-toggle="tooltip"]').tooltip();
});