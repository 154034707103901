(() => {
    "use strict";

    const bootbox = window.AconfGlobal.bootbox;

    $(document).on('click', '[data-dialog]', function(){
        const target = $(this);
        const defaultLocale = 'zh_CN';
        const type = target.data('dialog');
        const option = {};
        const dataset = target.data();
        if (dataset.size) {
            option.size = dataset.size;
        }
        if (dataset.title) {
            option.title = dataset.title;
        }
        if (dataset.message) {
            option.message = dataset.message;
        }
        if (dataset.onEscape) {
            option.onEscape = dataset.onEscape == 'false' ? false : true;
        }
        if (dataset.show) {
            option.show = dataset.show == 'false' ? false : true;
        }
        if (dataset.backdrop) {
            option.backdrop = dataset.backdrop == 'false' ? false : true;
        }
        if (dataset.closeButton) {
            option.closeButton = dataset.closeButton == 'false' ? false : true;
        }
        if (dataset.animate) {
            option.animate = dataset.animate == 'false' ? false : true;
        }
        if (dataset.className) {
            option.className = dataset.className;
        }
        if (dataset.locale) {
            option.locale = dataset.locale;
        }
        if (dataset.swapButtonOrder) {
            option.swapButtonOrder = dataset.swapButtonOrder == 'true' ? true : false;
        }
        if (dataset.centerVertical) {
            option.centerVertical = dataset.centerVertical == 'true' ? true : false;
        }
        if (dataset.scrollable) {
            option.scrollable = dataset.scrollable == 'true' ? true : false;
        }
        let dialog;

        const locale = dataset.locale || defaultLocale;

        switch (type) {
            case 'alert':
                option.buttons = {
                    ok: {
                        'label': bootbox.locales(locale)?.OK ?? bootbox.locales(defaultLocale).OK,
                        'className': 'btn-aconf-primary',
                    }
                };
                dialog = bootbox.alert(option);
                break;
            case 'confirm':
                option.buttons = {
                    confirm: {
                        label: bootbox.locales(locale)?.CONFIRM ?? bootbox.locales(defaultLocale).CONFIRM,
                        className: 'btn-danger'
                    },
                    cancel: {
                        label: bootbox.locales(locale)?.CANCEL ?? bootbox.locales(defaultLocale).CANCEL,
                        className: 'btn-light'
                    }
                };
                option.callback = res => {
                    target.trigger('bootbox.confirm.callback');

                    if (res && dataset.href) {
                        let data = {};

                        if (dataset.withCellChecked != undefined) {
                            let id = [];
                            $('.datatable-cell-check .check-single input[type="checkbox"]:checked').each((i, e) => {
                                id.push(e.value);
                            });
                            data.id = id;
                        }

                        $.ajax({
                            url: dataset.href,
                            dataType: 'json',
                            data: data,
                            method: dataset.method || 'GET',
                            success: res => {
                                if (typeof toastr != 'undefined') {
                                    toastr.options = {
                                        'positionClass': 'toast-top-center',
                                        'timeOut': '1500',
                                    };
                                    if (res.status) {
                                        toastr.success(res.msg || (locale =='zh_CN' ? '操作成功' : 'Operation succeed'));
                                        if (dataset.reload) {
                                            setTimeout(() => {
                                                location.reload();
                                            }, 1000);
                                        }
                                    } else {
                                        toastr.error(data.msg || (locale =='zh_CN' ? '操作失败' : 'Operation failed'));
                                    }
                                }
                            }
                        });
                    }
                };
                dialog = bootbox.confirm(option);
                break;
            case 'prompt':
                option.buttons = {
                    confirm: {
                        label: bootbox.locales(locale)?.CONFIRM ?? bootbox.locales(defaultLocale).CONFIRM,
                        className: 'btn-aconf-primary'
                    },
                    cancel: {
                        label: bootbox.locales(locale)?.CANCEL ?? bootbox.locales(defaultLocale).CANCEL,
                        className: 'btn-aconf-warning'
                    }
                };
                option.callback = res => {
                    target.trigger('bootbox.prompt.callback');
                };
                dialog = bootbox.prompt(option);
                break;
            case 'custom':
            default:
                if (dataset.target) {
                    option.message = $(dataset.target).html();
                    dialog = bootbox.dialog(option);
                    dialog.find('.bootbox-cancel').on('click', () => (dialog.modal('hide')));
                    target.trigger('bootbox.dialog.init');
                    if (dataset.afterInitEvent) {
                        eval(dataset.afterInitEvent);
                    }
                } else if (dataset.href) {
                    option.message = option.message || '<div></div>';
                    dialog = bootbox.dialog(option);
                    let data = {};
                    if (dataset.withCellChecked != undefined) {
                        let id = [];
                        $('.datatable-cell-check input[type="checkbox"]:checked').each((i, e) => {
                            id.push(e.value);
                        });
                        data.id = id;
                    }
                    const setDialogHeight = () => {
                        dialog.find('.modal-body').css({'max-height': (window.innerHeight - 100) + 'px', 'overflow-y': 'auto'});
                    };
                    $.get(dataset.href, data, content => {
                        dialog.find('.bootbox-body').html(content);
                        setDialogHeight();
                        $(window).on('resize', setDialogHeight);
                        dialog.find('.bootbox-cancel').on('click', () => {
                            dialog.modal('hide');
                            $(window).off('resize', setDialogHeight);
                        });
                        target.trigger('bootbox.dialog.init');
                        if (dataset.afterInitEvent) {
                            eval(dataset.afterInitEvent);
                        }
                    });
                } else if (dataset.iframe) {
                    option.message = '<iframe src="' + dataset.iframe + '" style="min-height:300px;width:100%" frameborder="0" class="' + dataset.iframeClass + '"></iframe>';
                    dialog = bootbox.dialog(option);
                    target.trigger('bootbox.dialog.init');
                    if (dataset.afterInitEvent) {
                        eval(dataset.afterInitEvent);
                    }
                }
                break;
        }
    });
})();