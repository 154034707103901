

const bootbox = window.AconfGlobal.bootbox;
const _lang = window.AconfGlobal.lang;
var cn_lang = { 'please_select_row': "您没有选择任何数据", 'user_quota': '服务配额', 'submission_quota': '论文配额', 'quota': '额度', 'used': '已用', 'upgrade': '升级', 'have_not_any_message_yet': '暂时没有公告消息', 'you_have_some_massege': '你有 [some] 条消息', 'prefix': "称呼", 'saving': "...", 'max': '最大值', 'min': '最小值', 'add_an_attachment': '上传附件', 'msg': "你确定要删除吗 ?", "warning": '警告', 'confirm': "确认", 'cancel': "取消", 'delete': '删除', "new_page_open": '新页面打开' };
var en_lang = { 'please_select_row': "No data selected", 'user_quota': 'User quota', 'submission_quota': 'Submission quota', 'quota': 'Quota', 'used': 'Used', 'upgrade': 'Upgrade', 'have_not_any_message_yet': 'Have not any message yet', 'you_have_some_massege': 'You have [some] messages', 'prefix': 'Prefix', 'saving': "...", 'max': 'Max', 'min': 'Min', 'add_an_attachment': 'Add files', 'msg': "Are you sure you want to delete the selected?", "warning": 'Warning', 'confirm': "Confirm", 'cancel': "Cancel", 'delete': 'Delete', "new_page_open": 'New page open' };
var GLOBAL_MSG = (typeof (_lang) == 'undefined' || _lang == 'en-us') ? en_lang : cn_lang;
const _global = {
    prompt: function (msg, status, time) {
        var icon = '';
        if (typeof (status) != 'undefined') {
            if (status === true || status == 'true' || status == 'success') {
                icon = '<i class="fa fa-check" aria-hidden="true"></i>';
            } else if (status === false || status == 'false' || status == 0 || status == 'fail') {
                icon = '<i class="fa fa-times" aria-hidden="true"></i>';
            } else {
                icon = '<i class="fa fa-info-circle" aria-hidden="true"></i>';
            }
        }
        var html = '<div class="alert-msg"><span class="msg-icon"></span><span class="msg-text">' + msg + '</span></div>';
        var alertMsg = $('body').find('.alert-msg');
        if (alertMsg.length == 0) {
            $('body').append(html);
            var alertMsg = $('body').find('.alert-msg');
        }
        alertMsg.find('.msg-icon').html(icon);
        alertMsg.find('.msg-text').html(msg);
        var w = alertMsg.outerWidth();
        alertMsg.css({
            'marginLeft': -w / 2
        });
        alertMsg.fadeIn('fast');
        if (time != -1) {
            if (!time) {
                time =2;
            }
            time = time * 1000;
            setTimeout(function() {
                alertMsg.remove();
            }, time);
        }
    },
    va_verify: function(_this, va_type, is_mScroll, is_focus, va_custom_text) {
        //验证一个
        if (_this.prop('disabled')) {
            return true;
        }
        va_type = va_type ? va_type : 'va_verify';
        var parent = _this.parent();
        var position_left = _this.position().left;
        var position_top = _this.position().top;
        var this_marginLeft = _this.css('margin-left');
        var this_marginTop = _this.css('margin-top');
        var this_w = _this.outerWidth();
        var this_h = _this.outerHeight();
        var va_error_left = parseInt(position_left) + parseInt(this_marginLeft);
        var va_error_top = parseInt(position_top) + parseInt(this_h) + parseInt(this_marginTop) + parseInt(2);
        var va_error_w = parseInt(this_w);
        var _verify = true;
        var val = $.trim(_this.val());
        if (_this.is('select') && val == 0) {
            val = '';
        }
        var defaults = new Array('va-required','va-email','va-number','va-max','va-min','va-repeat','va-select','va-num-min');
        var attrs = _global.get_element_va_attr(_this, defaults);
        if (va_type == "va_custom") {
            if (_this.attr('va-custom') != 'undefined') {
                var va_text = _this.attr('va-custom');
                va_text = va_custom_text ? va_custom_text : va_text;
                if (parent.find('.va').length > 0) {
                    parent.find('.va').remove();
                }
                $('<span class="va va-error"></span>').css({
                    left: va_error_left,
                    top: va_error_top,
                    maxWidth: va_error_w
                }).appendTo(parent).text(va_text).append('<i></i>');
                return false;
            }
        } else if (va_type == "va_help") {
            if (_this.attr('va-help') != 'undefined') {
                var va_text = _this.attr('va-help');
            }
            if (parent.find('.va').length > 0) {
                parent.find('.va').remove();
            }
            if (parent.css('position') != 'relative') {
                parent.css('position', 'relative');
            }
            $('<span class="va va-help"></span>').css({
                left: va_error_left,
                top: va_error_top,
                maxWidth: va_error_w
            }).appendTo(parent).text(va_text).append('<i></i>')
            return false;
        } else {
            if (!attrs) {
                _verify = true;
            } else {
                $.each(attrs, function(i) {
                    var va_text = $.trim(_this.attr(attrs[i]));
                    //验证文本
                    switch (attrs[i]) {
                    case 'va-required':
                        var verify = val ? true : false;
                        break;
                    case 'va-select':
                        var name = _this.attr('name');
                        if (typeof name == 'undefined' || name.length == 0) {
                            var verify = _this.prop('checked');
                            break;
                        }

                        var verify = $('input[name="' + name + '"][va-select]:checked').length > 0;
                        break;
                    case 'va-radio':
                        var compared_name = va_text;
                        var va_text = "此项必填";
                        var verify = $("input[va-radio][name=" + compared_name + "]:checked").length ? true : false;
                        break;
                    case 'va-email':
                        // var reg = /^([a-zA-Z0-9_-](\.)*)+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,20}){1,20})+$/;
                        var reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
                        var verify = reg.test(val);
                        break;
                    case 'va-number':
                        var verify = !isNaN(val.replace(/\s/g, ""));
                        break;
                    case 'va-max':
                        var length = va_text;
                        va_text = _this.attr('va-max-text');
                        var verify = val.length > length ? false : true;
                        break;
                    case 'va-min':
                        var length = va_text;
                        va_text = _this.attr('va-min-text');
                        var verify = length > val.length ? false : true;
                        break;
                    case 'va-repeat':
                        var compared_name = va_text;
                        var va_text = _this.attr('va-repeat-text');
                        var verify = $('input[name="' + compared_name + '"]').val() == val ? true : false;
                        break;
                    case 'va-num-min':
                        var compared_name = va_text;
                        var va_text = _this.attr('va-num-text');
                        var verify = val >= compared_name ? true : false;
                        break;
                    }
                    if (!verify) {
                        is_mScroll ? _global.mScroll(_this) : '';
                        is_focus ? _this.focus() : '';
                        if (parent.find('.va').length > 0) {
                            parent.find('.va').remove();
                        }
                        if (parent.css('position') != 'relative') {
                            parent.css('position', 'relative');
                        }
                        _this.addClass('ipt-error');
                        /**/
                        $('<span class="va va-error"></span>').css({
                            left: va_error_left,
                            top: va_error_top,
                            maxWidth: va_error_w
                        }).appendTo(parent).text(va_text).append('<i></i>');
                        _verify = false;
                        return false;
                    } else {
                        _this.removeClass('ipt-error');
                        parent.find('.va-error').remove();
                        _verify = true;
                    }
                });
            }
            $('.va').click(function() {
                $(this).remove();
            });
            return _verify;
        }
    },
    get_element_va_attr: function(_this, defaults_attr) {
        //返回某个元素的验证属性
        var attrs = new Array();
        for (var i in defaults_attr) {
            if (typeof (_this.attr(defaults_attr[i])) != 'undefined') {
                attrs.push(defaults_attr[i]);
            }
        }
        return attrs.length > 0 ? attrs : false;
    },
    va: function(form, va_type, is_mScroll, is_focus) {
        //mScroll是否滚动
        var $form = $(form);
        var verify = true;
        var ipts = $form.find('input,select,textarea');
        ipts.on('keydown mousedown', function() {
            if ($(this).parent().find('.va-error').length > 0) {
                $(this).parent().find('.va-error').remove();
            }
        });
        $.each(ipts, function(i) {
            if ($(this).parent().find('.va-help').length > 0) {
                $(this).parent().find('.va-help').remove();
            }
            verify = _global.va_verify($(this), 'va_verify', is_mScroll, is_focus);
            if (!verify) {
                return false;
            }
        });
        return verify;

    },
    getYearList: function (year) {
        var html = "";
        for (var i in M.year) {
            if (year == M.year[i]) {
                html += '<option selected="selected" value="' + M.year[i] + '">' + M.year[i] + '</option>';
            } else {
                html += '<option value="' + M.year[i] + '">' + M.year[i] + '</option>';
            }
        }
        return html;
    },
    getPrefix: function (prefix) {
        var html = "<option value=''>" + GLOBAL_MSG.prefix + "</option>";
        for (var i in M.prefix) {
            if (prefix == M.prefix[i]['v']) {
                html += '<option selected="selected" value="' + M.prefix[i]['v'] + '">' + M.prefix[i]['code'] + '</option>';
            } else {
                html += '<option value="' + M.prefix[i]['v'] + '">' + M.prefix[i]['code'] + '</option>';
            }
        }
        return html;
    },
    setProvince: function (lang, iso_code, provinceTag, init_province) {
        if (typeof provinceTag == 'string') {
            provinceTag = $(provinceTag);
        }
        provinceTag.hide();
        $.getJSON("/common/data/province/" + lang + "/" + iso_code + ".json", function (data, status, x) {
            if (status == 'success') {
                if (data.length > 0) {
                    provinceTag.show();
                }
                var html = "";
                if (lang == 'en-us') {
                    var please_select_provice = "Please select province/state";
                } else if (lang == 'zh-cn') {
                    var please_select_provice = "请选择省份/州";
                }
                html += "<option value='0'>" + please_select_provice + "</option>";
                if (data) {
                    $.each(data, function (i, n) {
                        if (init_province == n.abbreviation) {
                            html += "<option selected='selected' value='" + n.abbreviation + "' data-name='" + n.name + "'>" + n.name + "</option>";
                        } else {
                            html += "<option value='" + n.abbreviation + "' data-name='" + n.name + "'>" + n.name + "</option>";
                        }
                    });
                }
                provinceTag.html(html);
            }
        });

    },
    mScroll: function (tagName) {
        $("html,body").stop(true);
        var offsetTop = $(tagName).offset().top - 50;
        $("html,body").animate({ scrollTop: offsetTop }, 100);
    },
    textareaAutoHeight: function (element) {
        element.style.height = "5px";
        element.style.height = (element.scrollHeight) + "px";
    },
    setCountry: function (lang, tagName, init_iso_code) { //语言
        $.getJSON('/common/data/country/' + lang + '.json', function (data, status, x) {
            if (status == 'success') {
                var html = '';
                if (lang == 'en-us') {
                    html += _global.get_option_string(M.hot_country_en, init_iso_code);
                } else if (lang == 'zh-cn') {
                    html += _global.get_option_string(M.hot_country_zh, init_iso_code);
                }
                html += _global.get_option_string(data, init_iso_code);
                $(tagName).html(html);
            }
        });
    },
    getAreaCode: function (country_code) {
        if (country_code) {
            var area_code = '';
            if (country_code.indexOf('-') > 0) {
                area_code = country_code.substring(country_code.indexOf("-") + 1, country_code.length);
            } else {
                area_code = country_code;
            }
            return area_code;
        } else {
            return;
        }
    },
    getCountryCode: function (country_code) {
        if (country_code.indexOf('-') > 0) {
            country_code = country_code.substring(0, country_code.indexOf("-"));
        }
        return country_code;
    },
    init_country_province: function(lang, countryTag, provinceTag, init_iso_code, init_province) {
        if (typeof countryTag == 'string') {
            countryTag = $(countryTag);
        }

        $.getJSON('/common/data/country/' + lang + '.json', function(data, status, x) {
            if (status == 'success') {
                var html = '';
                if (lang == 'en-us') {
                    html += _global.get_option_string(M.hot_country_en, init_iso_code);
                } else if (lang == 'zh-cn') {
                    html += _global.get_option_string(M.hot_country_zh, init_iso_code);
                }
                html += _global.get_option_string(data, init_iso_code);
                countryTag.html(html);
                var _iso_code = countryTag.val();
                if (_iso_code && _iso_code != 0) {
                    _global.setProvince(lang, _iso_code, provinceTag, init_province);
                }
                var country_code = countryTag.find('option:selected').attr('country_code');
            }
        });
        countryTag.change(function() {
            var change_iso_code = $(this).val();
            _global.setProvince(lang, change_iso_code, provinceTag);
        });
    },
    init_country_province_v2: function (lang, countrytTagName, provinceTagName, areaTagName, init_iso_code, init_province) {
        $.getJSON('//file.aconf.org/public/data/country/' + lang + '.json', function (data, status, x) {
            const GLOBALS = [
              {
                value: '0',
                title: '所有国家'
              },
              {
                value: 'G0',
                title: '全球组织'
              },
              {
                value: 'G1',
                title: '亚洲'
              },
              {
                value: 'G2',
                title: '美洲'
              },
              {
                value: 'G3',
                title: '欧洲'
              },
              {
                value: 'G4',
                title: '非洲'
              },
              {
                value: 'G5',
                title: '中东'
              },
              {
                value: 'G6',
                title: '南美'
              },
              {
                value: 'G7',
                title: '北美'
              },
              {
                value: 'G9',
                title: '其他'
              },
            ];
            if (status == 'success') {
              var html = '';
              GLOBALS.forEach(function(gl) {
                if (init_iso_code == gl.value) {
                  html += `<option value='${gl.value}' selected="selected">${gl.title}</option>`;
                } else {
                  html += `<option value='${gl.value}'>${gl.title}</option>`;
                }
              });
                // var html = (init_iso_code == 'G0') ? "<option value='0'>所有国家</option><option value='G0' selected='selected'>全球组织</option>" : "<option value='0'>所有国家</option><option value='G0'>全球组织</option>";
                if (lang == 'en-us') {
                    html += _global.get_option_string(M.hot_country_en, init_iso_code);
                } else if (lang == 'zh-cn') {
                    html += _global.get_option_string(M.hot_country_zh, init_iso_code);
                }
                html += _global.get_option_string(data, init_iso_code);
                $(countrytTagName).html(html);
                var _iso_code = $(countrytTagName).val();
                _global.setProvince(lang, _iso_code, provinceTagName, init_province);
                var country_code = $(countrytTagName).find('option:selected').attr('country_code');
                var area_code = _global.getAreaCode(country_code);
                $(areaTagName).val(area_code);
            }
        });
    },
    returnParams: function () {
        var vars = [],
            hash;
        var url = window.location.href;
        if (window.location.search) {
            var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for (var i = 0; i < hashes.length; i++) {
                hash = hashes[i].split('=');
                hash[0] = decodeURIComponent(hash[0].replace(/\+/g, '%20'));
                vars[hash[0]] = decodeURIComponent(hash[1].replace(/\+/g, '%20'));
            }
        } else if (url.indexOf('-') > -1) {
            var replaceString = window.location.protocol + '//' + window.location.host;
            var href = url.replace(replaceString, '');
            href = href.replace('.html', '');
            var hashes = href.slice(href.indexOf('.') + 1).split('-');
            for (var i = 0; i < hashes.length;) {
                key = decodeURIComponent(hashes[i].replace(/\+/g, '%20'));
                vars[key] = decodeURIComponent(hashes[i + 1].replace(/\+/g, '%20'));
                i = i + 2;
            }
        } else {
            var replaceString = window.location.protocol + '//' + window.location.host;
            var href = url.replace(replaceString, '');
            href = href.replace('.html', '');
            var hashes = href.split('/');
            for (var i = 3; i < hashes.length;) {
                var key = decodeURIComponent(hashes[i].replace(/\+/g, '%20'));
                vars[key] = decodeURIComponent(hashes[i + 1].replace(/\+/g, '%20'));
                i = i + 2;
            }
        }
        return vars;

    },
    returnQuery: function (options) {
        var vars = _global.returnParams();
        for (var ii in options) {
            vars[ii] = options[ii];
        }
        var _vars = Array();
        for (var n in vars) {
            _vars.push('' + n + '=' + vars[n] + '');
        }
        return _vars.join('&');
    },
    get_option_string: function (obj, iso_code) {
        var html = '';
        $.each(obj, function (i, n) {
            html += '<optgroup label="' + i + '">';
            $.each(n, function (j, country) {
                if (iso_code && iso_code == country.iso_code) {
                    html += '<option country_id=' + country.country_id + ' selected="selected" value=' + country.iso_code + ' country_code=' + country.country_code + '>' + country.name + '</option>';
                } else {
                    html += '<option country_id=' + country.country_id + ' value=' + country.iso_code + ' country_code=' + country.country_code + '>' + country.name + '</option>';
                }
            });
            html += '</optgroup>';
        });
        return html;
    },
    modal_dialog_init : function(href, title, opts,size,is_scroll) {
        var opts = opts || [];
        var className = "";
        if (opts instanceof Array) {
            //兼容原来opts做个一个参数classN
            if (opts.classN) {
                className += ' ' + opts.classN;
            }
        }
        if (opts.className) {
            className += ' ' + opts.className;
        }
        if (opts.is_html) {
            className += " dialog-html";
        }
        if (opts['before-init-event'] != undefined) {
            //弹出前事件
            eval(opts['before-init-event']);
        }

        var dialog = bootbox.dialog({
            scrollable: is_scroll,
            size: size,
            animate: true,
            className: className,
            message: _global.M.loading,
            title: title,
        });

        var modal = dialog.find('.modal-dialog');
        var body = dialog.find('.bootbox-body');
        // setDialogHeight(modal);
        if (href.indexOf('#') == 0) {
            //弹出页面元素里的内容
            if ($(href).html != undefined) {
                body.html($(href).html());
                if (body.find('.footer-bar').length != 0) {
                    modal.find('.modal-body').css({
                        'paddingBottom': 50
                    });
                }
            } else {
                body.html('Obtain content error.');
            }
        } else {
            if (opts.is_html) {
                var html = '<iframe src="' + href + '"  id="dialog_iframepage" onload="changeFrameHeight()"  frameborder="0" class="statistics-iframe"></iframe>';
                body.html(html);
            } else {
                $.get(href, opts.query, function(html, status) {
                    if (status == 'success') {
                        body.html(html);
                        if (body.find('.footer-bar').length != 0) {
                            modal.find('.modal-body').css({
                                'paddingBottom': 50
                            });
                        }
                    }
                });
            }
        }
        if (opts['after-init-event'] != undefined) {
            //弹出后事件
            eval(opts['after-init-event']);
        }
    },
    cancel : function(e) {
        if (typeof e == 'undefined') {
            bootbox.hideAll();
        } else {
            if ($('.bootbox').length == 1) {
                bootbox.hideAll();
            } else {
                var modal = $(e).parents('.bootbox');
                modal.next('.modal-backdrop').remove();
                modal.remove();
            }
        }
    },
    get_data: function (url, tagName, tdNum) {
        if (tdNum) {
            var load = '<tr><td colspan="' + tdNum + '">' + M.loading_squared_gray_32 + '</td></tr>';
        } else {
            var load = M.loading_squared_gray_32;
        }
        $(tagName).html(load);
        $.get(url, function (data, status, x) {
            if (status == 'success') {
                $(tagName).html(data);
            }
        });
    },
    getUrlVars: function () {
        var vars = [],
            hash;
        var url = window.location.href;
        if (url.indexOf('?') !== -1) {
            var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for (var i = 0; i < hashes.length; i++) {
                hash = hashes[i].split('=');
                hash[0] = decodeURIComponent(hash[0].replace(/\+/g, '%20'));
                vars[hash[0]] = decodeURIComponent(hash[1].replace(/\+/g, '%20'));
            }
            return vars;
        } else {
            return false;
        }
    },
    getUrlVar: function (name) {
        return $.getUrlVars()[name];
    },
    create_input_html: function (from_tag) {
        vars = _global.getUrlVars();
        vars['m'] = module_name.toLowerCase();
        vars['a'] = action_name;
        var html = '';
        for (name in vars) {
            if (name !== 'p') {
                if ($(from_tag + ' input[name="' + name + '"]').length == 0 && $(from_tag + ' select[name="' + name + '"]').length == 0) {
                    html += '<input type="hidden" name="' + name + '" value="' + vars[name] + '" />'
                }
            }
        }
        $(from_tag).append(html);
    },
     //tag是id选择器 ，time是事件（秒）
    alert_info:function (code,status,time,tag) {
        if (!time) {
            time = 1.5;
        }
        const bootbox = window.AconfGlobal.bootbox;

        bootbox.alert({
            title : (status == true ) ? (_lang == 'zh-cn' ? '成功' : 'Successful') : (_lang == 'zh-cn' ? '失败' : 'Failed'),
            message: '<div id="attendee_form_alert_info" class="alert_info">' +
                '<div><span class="ui-icon ui-icon-alert" style="float:left; margin:3px 12px 20px 0;"></span><span class="text">' + code + '</span></div></div>',
            onShown: e => {
                setTimeout(() => {
                    if (tag) {
                        bootbox.hideAll();
                    }  else {
                        if ($('.bootbox').length == 1) {
                            bootbox.hideAll();
                        } else {
                            var modal = $('#attendee_form_alert_info').parents('.bootbox');
                            modal.next('.modal-backdrop').remove();
                            modal.remove();
                        }
                    }

                }, parseInt(time)*1000);
            },
            buttons: {
                ok: {
                    'label' : (_lang == 'zh-cn') ? '确定' : 'Confirm',
                    'className': 'btn-aconf-primary',
                }
            }
        });
    },
    M:{
        loading: '<div style="text-align: center;"><img src="//file.aconf.org/public/images/loading/32_32_squared_gray.gif" alt=""></div>',
        loading_primary_220: '<div style="text-align: center;"><img src="//file.aconf.org/public/images/loading/244_10_primary.gif" alt=""></div>',
        loading_gray_110: '<div style="text-align: center;"><img src="//file.aconf.org/public/images/loading/110_5_gray.gif" alt=""></div>',
        loading_squared_gray_20: '<div style="text-align: center;"><img src="//file.aconf.org/public/images/loading/20_20_squared_gray.gif" alt=""></div>',
        loading_squared_gray_32: '<div style="text-align: center;"><img src="//file.aconf.org/public/images/loading/32_32_squared_gray.gif" alt=""></div>',
        loading_squared_gray_45: '<div style="text-align: center;"><img src="//file.aconf.org/public/images/loading/45_45_squared_gray.gif" alt=""></div>',
        loading_squared_gray_64: '<div style="text-align: center;"><img src="//file.aconf.org/public/images/loading/64_64_squared_gray.gif" alt=""></div>',
        year: ['2010', '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000', '1999', '1998', '1997', '1996', '1995', '1994', '1993', '1992', '1991', '1990', '1989', '1988', '1987', '1986', '1985', '1984', '1983', '1982', '1981', '1980', '1979', '1978', '1977', '1976', '1975', '1974', '1973', '1972', '1971', '1970', '1969', '1968', '1967', '1966', '1965', '1964', '1963', '1962', '1961', '1960', '1959', '1958', '1957', '1956', '1955', '1954', '1953', '1952', '1951', '1950', '1949', '1948', '1947', '1946', '1945', '1944', '1943', '1942', '1941', '1940', '1939', '1938', '1937', '1936', '1935', '1934', '1933', '1932', '1931'],
        prefix: [{ "v": "Miss", "code": "Miss (Miss)" }, { "v": "Mr.", "code": "Mr. (Mister)" }, { "v": "Mrs.", "code": "Mrs. (Mrs.)" }, { "v": "Ms.", "code": "Ms. (Miss/Mrs)" }, { "v": "Prof.", "code": "Prof. (Professor)" }, { "v": "Dr.", "code": "Dr. (Doctor)" }, { "v": "Eng.", "code": "Eng. (Engineer)" }, { "v": "Engr.", "code": "Engr. (Engineer)" }, { "v": "Ing.", "code": "Ing. (Engineer)" }, { "v": "Ingr.", "code": "Ingr. (Engineer)" }, { "v": "Adm.", "code": "Adm. (Admiral)" }, { "v": "Brig", "code": "Brig (Brigadier General)" }, { "v": "Brig.", "code": "Brig. (Brigadier General)" }, { "v": "Bro.", "code": "Bro. (Brother)" }, { "v": "Capt.", "code": "Capt. (Captain)" }, { "v": "Cdr.", "code": "Cdr. (Commander)" }, { "v": "Cdt.", "code": "Cdt. (Cadet)" }, { "v": "Chief", "code": "Chief (Chief)" }, { "v": "Col.", "code": "Col. (Colonel)" }, { "v": "Count", "code": "Count (Count)" }, { "v": "Dean", "code": "Dean (Dean)" }, { "v": "Ens.", "code": "Ens. (Ensign)" }, { "v": "Gen.", "code": "Gen. (General)" }, { "v": "Hon.", "code": "Hon. (Honorable)" }, { "v": "Lt.", "code": "Lt. (Lieutenant)" }, { "v": "LtCdr.", "code": "LtCdr. (Lieutenant Commander)" }, { "v": "LtCol.", "code": "LtCol. (Lieutenant Colonel)" }, { "v": "LtGen.", "code": "LtGen. (Lieutenant General)" }, { "v": "Madam", "code": "Madam (Madam)" }, { "v": "Maj.", "code": "Maj. (Major)" }, { "v": "MajGen.", "code": "MajGen. (Major General)" }, { "v": "Prince", "code": "Prince (Prince)" }, { "v": "RAdm.", "code": "RAdm. (Rear Admiral)" }, { "v": "Rev.", "code": "Rev. (Reverend)" }, { "v": "Sir", "code": "Sir (Sir)" }],
        hot_country_zh: { "选择您所在的国家或地区？": [{ "country_id": "0", "iso_code": "0", "country_code": "0", "name": "全部地区" }, { "country_id": "1045", "iso_code": "CN", "country_code": "86", "name": "中国大陆" }, { "country_id": "1228", "iso_code": "US", "country_code": "1", "name": "美国" }, { "country_id": "1226", "iso_code": "GB", "country_code": "44", "name": "英国" }, { "country_id": "1039", "iso_code": "CA", "country_code": "1", "name": "加拿大" }, { "country_id": "1082", "iso_code": "DE", "country_code": "49", "name": "德国" }, { "country_id": "1076", "iso_code": "FR", "country_code": "33", "name": "法国" }, { "country_id": "1107", "iso_code": "IT", "country_code": "39", "name": "意大利" }, { "country_id": "1098", "iso_code": "HK", "country_code": "852", "name": "中国香港" }, { "country_id": "1101", "iso_code": "IN", "country_code": "91", "name": "印度" }, { "country_id": "1109", "iso_code": "JP", "country_code": "81", "name": "日本" }, { "country_id": "1115", "iso_code": "KR", "country_code": "82", "name": "韩国" }, { "country_id": "1208", "iso_code": "TW", "country_code": "886", "name": "中国台湾" }] },
        hot_country_en: { "Where are you form?": [{ "country_id": "0", "iso_code": "0", "country_code": "0", "name": "All" }, { "country_id": "1045", "iso_code": "CN", "country_code": "86", "name": "China (Mainland)" }, { "country_id": "1228", "iso_code": "US", "country_code": "1", "name": "United States" }, { "country_id": "1226", "iso_code": "GB", "country_code": "44", "name": "United Kingdom" }, { "country_id": "1039", "iso_code": "CA", "country_code": "1", "name": "Canada" }, { "country_id": "1082", "iso_code": "DE", "country_code": "49", "name": "Germany" }, { "country_id": "1076", "iso_code": "FR", "country_code": "33", "name": "France" }, { "country_id": "1107", "iso_code": "IT", "country_code": "39", "name": "Italy" }, { "country_id": "1098", "iso_code": "HK", "country_code": "852", "name": "Hong Kong, China" }, { "country_id": "1101", "iso_code": "IN", "country_code": "91", "name": "India" }, { "country_id": "1109", "iso_code": "JP", "country_code": "81", "name": "Japan" }, { "country_id": "1115", "iso_code": "KR", "country_code": "82", "name": "South Korea" }, { "country_id": "1208", "iso_code": "TW", "country_code": "886", "name": "Taiwan, China" }] }
    }
};
const M = _global.M;
// 删除,存在属性 data-delete data-href、data-msg,data-id 后端返回{'code'=>提示信息,'status'=>状态}
// data-href:删除后端地址，data-msg:提示信息,data-id:删除记录id
$(document).on('click', '[data-confirm]', function (event) {
    var obj = $(event.currentTarget);
    if (obj.attr('disabled') == 'disabled') {
        return false;
    }
    var msg = obj.data('msg') ? obj.data('msg') : GLOBAL_MSG.msg;
    msg = '<div style="padding:15px">' + msg + '</div>';

    var href = obj.data('href');
    bootbox.dialog({
        className: 'dialog-confirm',
        size: 'small',
        message: msg,
        title: GLOBAL_MSG.warning,
        animate: false,
        buttons: {
            danger: {
                label: GLOBAL_MSG.confirm,
                className: "btn-danger",
                callback: function (event) {
                    obj.prop('disabled', true).addClass('btn-loading');
                    var data = { confirm: "confirm" };
                    if (typeof (obj.data('confirm-lot')) != 'undefined') {
                        var ids = Array();
                        $('[checked-target]:checked').each(function () {
                            ids.push($(this).val());
                        });
                        if (ids.length > 0) {
                            data.id = ids.join('_');
                        }
                        ajaxReturn(href, data);
                    } else {
                        var id = obj.data('id');
                        if (typeof id != 'undefined' && id !== '') {
                            data.id = id;
                        }

                        ajaxReturn(href, data);
                    }
                    return false;
                }
            },
            cancel: {
                label: GLOBAL_MSG.cancel,
                className: "btn-cancel btn-default"
            }
        }
    });
    $('.dialog-confirm .btn-danger').focus();
    var h = parseInt($(window).outerHeight() / 2) - parseInt($('.dialog-confirm .modal-dialog').outerHeight() / 2) - parseInt(100);
    $('.dialog-confirm .modal-dialog').css({ 'marginTop': h });
});
//ajax请求操作
$(document).on('click', '[data-action]', function (event) {
    if ($(event.currentTarget).attr('disabled') == 'disabled') {
        return false;
    }
    _global.prompt(GLOBAL_MSG.saving);
    var $clickTarget = $(event.currentTarget);
    var id = $(event.currentTarget).data('id');
    var href = $(event.currentTarget).data('href');
    ajaxReturn(href, { confirm: "confirm", id: id });
});
/**
 * 弹出框可接受来自网址或者内部元素作为内容
 * 触发的对象需要至少存在data-dialog、data-href、data-title三个属性，可选属性data-after-event/data-before-event
 */
// $(document).on('click', '[data-dialog]', function (event) {
//     if ($(event.currentTarget).attr('disabled') == 'disabled') {
//         return false;
//     }
//     var href = $(event.currentTarget).data('href');
//     var title = $(event.currentTarget).data('title');
//     var opts = [];
//     if (typeof ($(event.currentTarget).data('before-init-event')) != 'undefined') {
//         opts['before-init-event'] = $(event.currentTarget).data('before-init-event');
//     }
//     if (typeof ($(event.currentTarget).data('after-init-event')) != 'undefined') {
//         opts['after-init-event'] = $(event.currentTarget).data('after-init-event');
//     }
//     if (typeof ($(event.currentTarget).data('dialog-html')) != 'undefined' || typeof ($(event.currentTarget).data('html')) != 'undefined') {
//         //内容为嵌入式网页
//         opts.is_html = true;
//     } else {
//         //获取网页内容嵌入到
//         opts.is_html = false;
//     }
//     _global.modal_dialog_init(href, title, opts);
// });

$(document).on('click', '[data-dialog-lot]', function (event) {
    var _this = $(event.currentTarget);
    if (_this.attr('disabled') == 'disabled') {
        return false;
    }
    if (typeof (_this.data('big')) != 'undefined') {
        var className = "dialog-big";
    } else {
        var className = "dialog-normal";
    }
    var href = _this.data('href');
    var title = _this.data('title');
    var id = Array();

    if (typeof $(this).data('id') != 'undefined') {
        id = $(this).data('id');
    } else {
        $('[checked-target]:checked').each(function () {
            id.push($(this).val());
        });
        id = id.join('_');
    }

    var dialog = bootbox.dialog({
        className: className,
        animate: true,
        message: _global.M.loading,
        title: title
    });
    var modal = dialog.find('.modal-dialog');
    var _body = dialog.find('.bootbox-body');
    $.get(href, { id: id }, function (result, status) {
        if (status == 'success') {
            _body.html(result);
            if ($('.bootbox-body .footer-bar').length != 0) {
                modal.find('.modal-body').css({ 'paddingBottom': 50 });
            }
        }
    });
});

//存在属性 data-dialog、data-href、data-title
$(document).on('click', '[data-dialog-big]', function (event) {
    var opts = [];
    opts.query = {};
    if ($(event.currentTarget).attr('disabled') == 'disabled') {
        return false;
    }
    opts.is_html = false;
    if (typeof ($(event.currentTarget).data('dialog-html')) != 'undefined' || typeof ($(event.currentTarget).data('html')) != 'undefined') {
        opts.is_html = true;
    }
    opts.className = 'data-dialog-big';
    if (typeof ($(event.currentTarget).data('className')) != 'undefined') {
        opts.className = opts.className + ' ' + $(event.currentTarget).data('className');
    }

    var id = Array();
    if (typeof $(this).data('id') != 'undefined') {
        id = $(this).data('id').toString();
    } else {
        $('[checked-target]:checked').each(function () {
            id.push($(this).val());
        });
        id = id.join('_');
    }
    if (id.length > 0) {
        opts.query.id = id;
    }

    var href = $(event.currentTarget).data('href');
    var title = $(event.currentTarget).data('title');
    opts.is_center = typeof ($(event.currentTarget).data('center')) != 'undefined' ? true : false;

    _global.modal_dialog_init(href, title, opts);
});

//存在属性 data-dialog、data-href、data-title
$(document).on('click', '[data-dialog-biggest]', function (event) {
    if ($(event.currentTarget).attr('disabled') == 'disabled') {
        return false;
    }
    var id = [];
    if ($(this).data('id')) {
        id = $(this).data('id');
    } else {
        $('[checked-target]:checked').each(function () {
            id.push($(this).val());
        });
        id = id.join('_');
    }

    var href = $(event.currentTarget).data('href');
    var title = $(event.currentTarget).data('title');
    var is_html = false;
    if (typeof ($(event.currentTarget).data('dialog-html')) != 'undefined' || typeof ($(event.currentTarget).data('html')) != 'undefined') {
        is_html = true;
    }
    bootbox.dialog({
        animate: false,
        className: 'dialog-biggest',
        message: M.loading,
        title: title
    });
    var modal = $('.modal .modal-dialog');
    var body = $('.modal .bootbox-body');
    if (is_html) {
        if (id !== '') {
            var i = href.lastIndexOf('?');
            if (i == -1) {
                href += '?id=' + id;
            } else if (i == href.length - 1) {
                href += 'id=' + id;
            } else {
                href += '&id=' + id;
            }
        }
        var html = '<iframe src="' + href + '"   frameborder="0" style="height:100%;width:100%" class="statistics-iframe"></iframe>';
        body.html(html);
        modal.find('.modal-header').append('<a target="_blank" href="' + href + '" class="modal-blank-click"  style="color:#ffffff;position: absolute;right: 64px;top: 10px;font-size: 14px;">' + GLOBAL_MSG.new_page_open + '</a>');
    } else {
        $.get(href, { id: id }, function (html, status) {
            if (status == 'success') {
                body.html(html);
                if ($('.bootbox-body .footer-bar').length != 0) {
                    modal.find('.modal-body').css({ 'paddingBottom': 50 });
                }
            }
        });
    }
});
/*加载远程片段*/
var remote = $('[data-remote]');
remote.each(function () {
    var url = $(this).data('remote');
    $(this).load(url);
});

/*页面跳转,创建表单并提交*/
$(document).on('click', '[data-submit]', function (event) {
    var $targetEvent = $(event.currentTarget);
    if ($targetEvent.attr('disabled') == 'disabled') {
        return false;
    }
    var action = $targetEvent.data('submit');
    var method = typeof ($targetEvent.data('method')) == 'undefined' ? 'POST' : $targetEvent.data('method');
    var target = typeof ($targetEvent.data('target')) == 'undefined' ? '_self' : $targetEvent.data('target');
    var form = document.createElement('form');
    form.style = "display:none;";
    form.method = method;
    form.action = action;
    form.target = target;
    /*条件开始*/
    if ($targetEvent.data('where')) {
        var where = $targetEvent.data('where').split('=');
        for (i = 0; i < where.length; i = i + 2) {
            var ipt = document.createElement('input');
            ipt.type = 'hidden';
            ipt.name = where[i];
            ipt.value = where[i + 1];
            form.appendChild(ipt);
        }
    }
    /*条件结束*/
    /*选中开始*/
    var selectIpt = document.createElement('input');
    var idString = [];
    $('[checked-target]:checked').each(function () {
        idString.push($(this).val());
    });
    idString = idString.join('_');
    selectIpt.type = 'hidden';
    selectIpt.name = 'selected';
    selectIpt.value = idString;
    form.appendChild(selectIpt);
    /*选中结束*/
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
});
/**
 * [ajaxReturn description]
 * @param  {[type]} href [description]
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
function ajaxReturn(href, data) {
    $.ajax({
        type: 'POST',
        url: href,
        data: data,
        dataType: 'json',
        success: function (result, status, x) {
            if (status == 'success') {
                if (result.code) {
                    _global.prompt(result.code, result.status);
                }
                if (result.status) {
                    setTimeout(function () {
                        window.location.reload();
                    }, 1000);
                }
                bootbox.hideAll();
            }
        }
    });
}
var cancel = function (e) {
    if (typeof e == 'undefined') {
        bootbox.hideAll();
    } else {
        var modal = $(e).parents('.bootbox');
        modal.next('.modal-backdrop').remove();
        modal.remove();
    }
};

//size 是弹框尺寸  一般large
//is_scroll 是否开启滚轮
$( 'form[pjax]').on('submit', function(event) {

    if (!_global.va($(event.currentTarget), 'va-verify', false, true)) {
        return false;
    }
    _global.prompt(GLOBAL_MSG.saving);
    $(event.currentTarget).find('[type="submit"]').prop('disabled', true).addClass('btn-loading');
});
$('form[pjax-ajax]').on('submit', function(event) {
    if (!_global.va($(event.currentTarget), 'va-verify', false, true)) {
        return false;
    }
    _global.prompt(GLOBAL_MSG.saving);
    var $target = $(event.currentTarget);
    $(event.currentTarget).find('[type="submit"]').prop('disabled', true).addClass('btn-loading');
    $(event.currentTarget).unbind('submit');
    $.ajax({
        type: "POST",
        dataType: 'JSON',
        url: $(event.currentTarget).attr('action'),
        data: $(event.currentTarget).serialize(),
        success: function(data, status, x) {
            if (status == 'success') {
                // 自定义的方法, 如果定义了会在 pjax 提交成功时调用
                if (typeof event.onPjaxSuccess == 'function') {
                    event.onPjaxSuccess(data);
                }

                setTimeout(function() {
                    _global.prompt(data.code, data.status);
                }, 500);
                setTimeout(function() {
                    $(event.currentTarget).find('[type="submit"]').prop('disabled', false).removeClass('btn-loading');
                }, 1000);
                if (data.status) {
                    var modal = $(event.currentTarget).parents('.bootbox');
                    if (modal.length > 0) {
                        modal.next('.modal-backdrop').remove();
                        modal.remove();
                    }
                    if (data.url) {
                        if (typeof ($target.data('top')) != 'undefined') {
                            top.location.href = data.url;
                        } else {
                            window.location.href = data.url;
                        }
                    } else {
                        if (typeof ($target.data('reload')) != 'undefined') {
                            setTimeout(function() {
                                window.location.reload();
                            }, 1000);
                        }
                    }
                }
            }
        }
    });
    return false;
});

window.AconfGlobal = window.AconfGlobal || {};
window.AconfGlobal._global = _global;

export {_global as _global};
